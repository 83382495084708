.html {
  height: 20px;
  float: left;
  width: 95%;
  animation: html 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.html:hover {
  color: #f4f4f4;
}
.figma {
  height: 20px;
  float: left;
  width: 80%;
  animation: figma 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.figma:hover {
  color: #f4f4f4;
}
.photoshop {
  height: 20px;
  float: left;
  width: 80%;
  animation: photoshop 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.photoshop:hover {
  color: #f4f4f4;
}
.javascript {
  height: 20px;
  float: left;
  width: 90%;
  animation: javascript 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.javascript:hover {
  color: #f4f4f4;
}
.git {
  height: 20px;
  float: left;
  width: 95%;
  animation: git 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.git:hover {
  color: #f4f4f4;
}
.reactjs {
  height: 20px;
  float: left;
  width: 92%;
  animation: reactjs 3s;
  text-align: center;
  font-size: x-small;
  color: transparent;
}
.reactjs:hover {
  color: #f4f4f4;
}

@keyframes html {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}
@keyframes figma {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}
@keyframes photoshop {
  0% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}
@keyframes javascript {
  0% {
    width: 0%;
  }
  100% {
    width: 90%;
  }
}
@keyframes git {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

@keyframes reactjs {
  0% {
    width: 0%;
  }
  100% {
    width: 92%;
  }
}
