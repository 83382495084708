#contact {
  display: block;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  margin: 0 0 10px 0;
  padding-top: 2rem;
  height: 100%;
  max-width: 100%;
  background-color: #131313;
  padding-bottom: 1rem;
}

@media only screen and (max-width: 375px) {
  #contact {
    padding: 1.1rem;
  }
}

@media only screen and (max-width: 320px) {
  #contact {
    padding: 1rem;
  }
}

header {
  text-align: center;
  padding-top: 2rem;
  justify-content: space-between 20px;
  max-width: 100%;
}

h1 {
  text-transform: lowercase;
  letter-spacing: 3px;
  font-family: "UnifrakturMaguntia", cursive;
  font-size: 2rem;
  color: #f4f4f4;
  padding-bottom: 3rem;
  text-shadow: 3px 3px 8px #ff0000;
}

.contact__p {
  color: #870000;
  padding-top: 1rem;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 30rem;
  padding: 20px;
}

@media only screen and (max-width: 768px) {
  .card {
    width: 20rem;
    max-width: 100%;
    border-radius: 1rem;
    height: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .card {
    width: 20rem;
    max-width: 100%;
    border-radius: 1rem;
    height: 100%;
  }
}

input {
  padding: 1rem;
  color: #131313;
  font-family: "Source Sans Pro", sans-serif;
}

.btn__contact {
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  color: #f4f4f4;
  border: solid 2px #f4f4f4;
  white-space: nowrap;
  font-family: "Source Sans Pro", sans-serif;
  background: transparent;
  text-transform: lowercase;
  border-radius: 15px;
  font-size: 16px;
  text-decoration: none;
  padding: 16px 36px;
}

.subject {
  width: 100%;
}

.name {
  width: 100%;
}

.message {
  width: 100%;
  height: 10rem;
  padding: 0;
  text-indent: 10px;
  text-align: left;
  padding: 5px;
  box-sizing: border-box;
}

.email {
  width: 100%;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.bi-instagram {
  width: 2rem;
  color: #800020;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.bi-envelope {
  width: 2rem;
  color: #800020;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.bi-linkedin {
  width: 2rem;
  color: #800020;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
