@import url("https://fonts.googleapis.com/css2?family=UnifrakturMaguntia&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nothing+You+Could+Do&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap");

.App {
  padding-left: 1rem;
}
body {
  background-color: #000;
}

.date {
  border: none;
  text-align: center;
  font-size: 1rem;
  font-family: "Roboto Mono", monospace;
  background-color: transparent;
  color: #bbbbbb;
}

footer {
  color: #bbbbbb;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  font-size: smaller;
  padding: 1.5rem;
  background-color: #000;
}
